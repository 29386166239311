import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Formik, Form, FastField, ErrorMessage } from "formik";
import * as Yup from "yup";
import { runtimeConfig } from "../../common/helpers/config";
import {
  defaultSuccessNotification,
  defaultFailureNotification
} from "../../common/helpers/UIkitNotifications";

const NewsletterForm = Yup.object().shape({
  salutation: Yup.string(),
  firstname: Yup.string(),
  lastname: Yup.string(),
  email: Yup.string()
    .email("Umgültige E-Mail Adresse")
    .required("Pflichtfeld"),
  privacy: Yup.bool()
    .required("Pflichtfeld")
    .test(
      "privacy-accepted",
      "Datenschutzbestimmungen müssen akzeptiert werden.",
      function(value) {
        return value === true;
      }
    )
});

function Newsletter({ auth }) {
  const [loading, setLoading] = useState(false);

  if (
    (!runtimeConfig.NL_CLIENT_ID ||
      !runtimeConfig.NL_CLIENT_SECRET ||
      !runtimeConfig.NL_FORM_ID ||
      !runtimeConfig.NL_GROUP_ID) &&
    auth
  ) {
    return (
      <div>
        <div id="nl-notification" data-uk-modal="">
          <div className="uk-modal-dialog uk-modal-body">
            <h2 className="uk-modal-title">Achtung, wir müssen reden.</h2>
            <p>
              Leider konnte ich keine Konfiguration für den Newsletter finden,
              wenn Sie über Newsletter-Anmeldeinformationen verfügen, tragen Sie
              diese an der entsprechenden Stelle ein oder ignorieren Sie diese
              Meldung.
            </p>
            <p className="uk-text-right">
              <button
                className="uk-button uk-button-default uk-modal-close"
                type="button"
              >
                Schließen
              </button>
            </p>
          </div>
        </div>
      </div>
    );
  } else if (
    !runtimeConfig.NL_CLIENT_ID ||
    !runtimeConfig.NL_CLIENT_SECRET ||
    !runtimeConfig.NL_FORM_ID ||
    !runtimeConfig.NL_GROUP_ID
  ) {
    return <div />;
  }

  return (
    <section className="uk-section uk-section-secondary uk-section-small">
      <div id="confirm" />
      <div className="uk-container uk-container-expand uk-dark">
        <h3>Newsletter abonnieren</h3>
        {loading ? (
          <div className="uk-flex uk-flex-center">
            <div data-uk-spinner="" />
          </div>
        ) : (
          <Formik
            initialValues={{
              salutation: "",
              firstname: "",
              lastname: "",
              email: "",
              privacy: false
            }}
            validationSchema={NewsletterForm}
            onSubmit={async values => {
              try {
                await setLoading(true);
                const { data } = await axios.post(
                  "https://cors-anywhere.herokuapp.com/" +
                    "https://rest.cleverreach.com/oauth/token.php",
                  {
                    grant_type: "client_credentials",
                    client_id: runtimeConfig.NL_CLIENT_ID,
                    client_secret: runtimeConfig.NL_CLIENT_SECRET
                  }
                );

                const existReceiver = await axios
                  .get(
                    "https://cors-anywhere.herokuapp.com/" +
                      `https://rest.cleverreach.com/v3/receivers.json/${values.email}`,
                    {
                      headers: {
                        Authorization: "Bearer " + data.access_token
                      },
                      params: { group_id: runtimeConfig.NL_GROUP_ID }
                    }
                  )
                  .catch(err => console.error("err", err.message));

                if (
                  !existReceiver ||
                  !existReceiver.data ||
                  existReceiver.status !== 200
                ) {
                  await axios.post(
                    "https://cors-anywhere.herokuapp.com/" +
                      `https://rest.cleverreach.com/v3/groups.json/${runtimeConfig.NL_GROUP_ID}/receivers`,
                    {
                      email: values.email,
                      deactivated: "1",
                      global_attributes: {
                        firstname: values.firstname,
                        lastname: values.lastname,
                        salutation: values.salutation
                      }
                    },
                    {
                      headers: {
                        Authorization: "Bearer " + data.access_token
                      }
                    }
                  );
                  defaultSuccessNotification(
                    "Sie haben sich erfolgreich für den Newsletter angemeldet."
                  );
                }

                await axios
                  .post(
                    "https://cors-anywhere.herokuapp.com/" +
                      `https://rest.cleverreach.com/v3/forms.json/${runtimeConfig.NL_FORM_ID}/send/activate`,
                    {
                      email: values.email,
                      doidata: {
                        user_ip: "1.2.3.4",
                        referer: "http://www.wayne-enterprises.com",
                        user_agent: "Brucilla/1.0 (HerOS/Linux)"
                      }
                    },
                    {
                      headers: {
                        Authorization: "Bearer " + data.access_token
                      }
                    }
                  )
                  .then(() =>
                    defaultSuccessNotification(
                      "Bitte berprüfen Sie Ihre E-Mails."
                    )
                  )
                  .catch(err => {
                    if (err.response.data.error.code === 403) {
                      defaultFailureNotification(
                        "Ihre E-Mail Adresse ist nicht erlaubt."
                      );
                    } else {
                      defaultSuccessNotification(
                        "Sie haben sich bereits für den Newsletter erfolgreich angemeldet."
                      );
                    }
                  });

                await setLoading(false);
              } catch (err) {
                await setLoading(false);

                return defaultFailureNotification(
                  err.response.data.error_description || err.message
                );
              }
            }}
          >
            {({ values }) => {
              return (
                <Form
                  className="uk-grid-small uk-child-width-auto"
                  data-uk-grid=""
                >
                  <div className="uk-width-1-5@m uk-width-1-2@s uk-width-1-1">
                    <FastField
                      component="select"
                      className="uk-select"
                      name="salutation"
                    >
                      <option value="">Anrede</option>
                      <option value="Herr">Herr</option>
                      <option value="Frau">Frau</option>
                      <option value="Divers">Divers</option>
                    </FastField>
                  </div>
                  <div className="uk-width-1-5@m uk-width-1-2@s uk-width-1-1">
                    <FastField
                      className="uk-input"
                      placeholder="Vorname"
                      name="firstname"
                    />
                  </div>
                  <div className="uk-width-1-5@m uk-width-1-2@s uk-width-1-1">
                    <FastField
                      className="uk-input"
                      placeholder="Nachname"
                      name="lastname"
                    />
                  </div>
                  <div className="uk-width-expand">
                    <FastField
                      className="uk-input"
                      placeholder="E-Mail *"
                      type="email"
                      name="email"
                    />
                    <ErrorMessage name="email">
                      {msg => <div className="uk-text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="uk-width-auto">
                    <button
                      type="submit"
                      className="uk-button uk-button-default"
                    >
                      Senden
                    </button>
                  </div>
                  <div className="uk-width-1-1">
                    <div className="uk-margin uk-grid-small uk-grid">
                      <div className="uk-width-auto">
                        <FastField
                          className="uk-checkbox"
                          type="checkbox"
                          name="privacy"
                          checked={values.privacy}
                        />
                      </div>
                      <div className="uk-width-expand uk-text-small">
                        <label htmlFor="privacy">
                          Ich bin damit einverstanden, dass mich die Dorr
                          Unternehmensgruppe GmbH & Co KG per eMail Newsletter
                          über ihre Dienstleistung & Produkte informieren darf.
                          Meine Daten werden ausschließlich zu diesem Zweck
                          genutzt. Insbesondere erfolgt keine Weitergabe an
                          unberechtigte Dritte. Mir ist bekannt, dass ich meine
                          Einwilligung jederzeit mit Wirkung für die Zukunft
                          widerrufen kann. Es gilt die Datenschutzerklärung der
                          Dorr Unternehmensgruppe GmbH & Co KG.
                        </label>
                      </div>
                      <ErrorMessage name="privacy">
                        {msg => (
                          <div className="uk-width-1-1 uk-text-danger">
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </section>
  );
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps, null)(Newsletter);
